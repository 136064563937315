<template>
  <form-wizard-step title="Deltagarens hälsa" :validate="validate" :before-leave="onBeforeLeave"
    class="supplement-health" @error="onValidationError" @leave="onLeave">
    <form ref="form" :class="{ 'was-validated': error }">
      <radio-group v-model="hasFoodAllergy" label="Har deltagaren matallergi och/eller specialkost?" variant="primary"
        lg :options="yesNoOptions" :state="hasFoodAllergyInvalid ? false : null" required />
      <fieldset v-if="hasFoodAllergy">
        <legend>Allergier eller specialkost</legend>
        <radio-group v-for="(allergy, index) in registration.details.foodAllergyListRaw" :key="index"
          v-model="allergy.value" :label="allergy.name" :options="yesNoOptions" variant="primary" />
        <custom-input v-model="registration.details.foodAllergyDescription"
          label="Övriga matallergier eller specialkost" variant="primary"
          placeholder="Ange eventuell allergi/specialkost som inte nämns ovan..." lg />
        <div class="alert alert-info font-size-sm">
          <strong>Är ditt barn känslig för viss mat?</strong>
          <p class="mb-0">
            Vi gör alltid vårt bästa för att anpassa maten efter de kostpreferenser som anges.
            Det kan dock vara utmanande att tillgodose alla önskemål och vi kan därför inte garantera att
            ditt barn får exakt den mat som den gillar.
            Tack för din förståelse!
          </p>
        </div>
      </fieldset>

      <radio-group v-model="hasDisorder"
        label="Har deltagaren övrig allergi, sjukdom eller diagnos som är bra att känna till?" variant="primary" lg
        :options="yesNoOptions" required />
      <custom-input v-if="hasDisorder" v-model="registration.details.disorderDescription"
        label="Beskriv övrig allergi, sjukdom eller diagnos" variant="primary" :required="hasDisorder" lg autofocus />

      <radio-group v-model="hasMedication" label="Tar deltagaren några mediciner som är bra att känna till?"
        variant="primary" lg :options="yesNoOptions" required />
      <custom-input v-if="hasMedication" v-model="registration.details.medicationDescription"
        label="Mediciner som intas innan och/eller under lägervistelse" variant="primary" :required="hasMedication" lg
        autofocus />

      <div v-if="hasDisorder || hasMedication" class="alert alert-info font-size-sm">
        <strong>Behöver ditt barn extra stöttning?</strong>
        <p class="mb-0">
          Vi gör alltid vårt bästa för att anpassa aktiviteter och rutiner efter deltagarens behov.
          Ibland kan ledare ha mycket att göra och det är därför viktigt att ditt barn kan sköta medicinering och
          rutiner på egen hand.
          Om du känner dig osäker, kontakta oss gärna för att diskutera möjligheter.
        </p>
      </div>

      <form-wizard-next-button />
    </form>
  </form-wizard-step>
</template>

<script lang="ts">
import { Vue, Component, Inject, Watch } from 'vue-property-decorator';
import { Registration } from '@/store/models/orderables/registration/Registration.model';
import { createYesNoOptions } from '@/store/models/orderables/registration/RegistrationDetails.model';

@Component
export default class HealthStep extends Vue {
  /**
   * Inject from parent page component
   */
  @Inject()
  registration!: Registration;

  hasFoodAllergy: boolean | null = null;
  hasFoodAllergyInvalid = false;
  hasDisorder: boolean | null = null;
  hasMedication: boolean | null = null;
  error = false;

  yesNoOptions = createYesNoOptions();

  created() {
    this.hasFoodAllergy = this.registration.details.hasFoodAllergy;
    this.hasDisorder = this.registration.details.hasDisorder;
    this.hasMedication = this.registration.details.hasMedication;
  }

  @Watch('hasFoodAllergy')
  onHasFoodAllergyChange() {
    // Reset error state
    if (this.hasFoodAllergyInvalid)
      this.hasFoodAllergyInvalid = false;
  }

  validate() {
    const form = this.$refs.form as HTMLFormElement;
    const valid = form.checkValidity();

    if (this.hasFoodAllergy && !this.registration.details.foodAllergyList.length && !this.registration.details.foodAllergyDescription) {
      this.hasFoodAllergyInvalid = true;
      return false;
    }

    this.hasFoodAllergyInvalid = false;
    return valid;
  }

  onValidationError() {
    this.error = true;
  }

  onBeforeLeave() {
    const { details } = this.registration;
    if (!this.hasFoodAllergy) {
      details.foodAllergyListRaw.forEach((allergy) => { allergy.value = false });
      details.foodAllergyDescription = '';
    }
    if (!this.hasDisorder)
      details.disorderDescription = '';
    if (!this.hasMedication)
      details.medicationDescription = '';

    return this.registration.saveDetails([
      'foodAllergyDescription',
      'foodAllergyList',
      'disorderDescription',
      'medicationDescription',
      'swimAllowed',
    ]);
  }

  onLeave() {
    this.error = false;
  }
}
</script>
