<template>
  <form-wizard-step title="Övrig information" :before-leave="onBeforeLeave">
    <div class="form-group">
      <label for="exampleFormControlTextarea1">Övrig information (valfritt)</label>
      <textarea id="exampleFormControlTextarea1" v-model="registration.details.otherInfo" class="form-control"
        rows="3" />
      <small class="text-muted">Finns det något mer vi behöver veta om deltagaren eller något som du vill
        förmedla?</small>
    </div>
    <form-wizard-next-button>Slutför</form-wizard-next-button>
  </form-wizard-step>
</template>

<script lang="ts">
import { Vue, Component, Inject } from 'vue-property-decorator';

import { Registration } from '@/store/models/orderables/registration/Registration.model';

@Component
export default class OtherInfoStep extends Vue {
  /**
   * Inject from parent page component
   */
  @Inject()
  registration!: Registration;

  onBeforeLeave() {
    return this.registration.saveDetails([
      'otherInfo',
    ]);
  }
}
</script>
