import { Component, Prop, InjectReactive, Mixins, Inject } from 'vue-property-decorator';
import { Timetable } from '@/store/models/orderables/registration/Timetable.model';
import { LoadGQLCityMixin } from '@/mixins/LoadGQLCityMixin';
import { Registration } from '@/store/models/orderables/registration/Registration.model';
import { CartItem } from '@/store/models/CartItem.model';

@Component
export default class SportAvailabilityItem extends Mixins(LoadGQLCityMixin) {
  Timetable = Timetable;

  @Prop()
  value!: Timetable;

  @Prop()
  sport!: GQL.Sport;

  @Prop()
  weeks!: GQL.Week[];

  @Prop(String)
  accordionParent!: string;

  @Prop(String)
  name!: string;

  @Prop()
  campType!: { name: string };

  @InjectReactive()
  editMode!: boolean;

  @Inject()
  cartItem!: CartItem<Registration>;

  get registration() {
    return this.cartItem.orderable;
  }

  /**
   * Get unique component id
   */
  get cid() {
    // @ts-ignore
    return this._uid;
  }

  /**
   * Radio options for each available weeb
   */
  get radioOptions() {
    return this.weeks.map((week) => {
      const timetable = this.sport.timetablesForBirthday!.find(t => t.week.id === week.id && t.occupancy >= 0);
      if (!timetable)
        return {
          text: `Vecka ${week.number}`,
          value: 'disabled',
          disabled: true,
          week,
        };

      return {
        text: `Vecka ${week.number}`,
        value: new Timetable(timetable, this.$data.activeCity),
        timetable,
        week,
      };
    }).sort((a, b) => a.week.number - b.week.number);
  }

  /**
   * Get timetable weeks availability
   */
  get availability() {
    const weekTitles = this.weeks.map(w => w.number);
    const weeks = this.sport.timetablesForBirthday!
      .map((timetable) => {
        weekTitles.splice(weekTitles.findIndex(w => w === timetable.week.number), 1);

        return {
          class: `status-${timetable.availabilityStatus.toLowerCase().replace('_', '-')}`,
          week: timetable.week.number,
        };
      });

    weekTitles.forEach(w => weeks.push({
      class: '',
      week: w,
    }));

    return weeks.sort((a, b) => a.week - b.week);
  }

  /**
   * Check if any week is full
   */
  get anyWeekIsFull() {
    return this.sport.timetablesForBirthday!
      .filter(t => t.occupancy >= 0)
      .some(timetable => !timetable.hasAvailableSeats);
  }
}
