<template>
  <!-- Fade between routes -->
  <transition name="fade" mode="out-in">
    <router-view />
  </transition>
</template>

<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class App extends Vue { }
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 250ms linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
