<template>
  <div class="card border border-light mb-2" :class="{ 'bg-light': completed }">
    <div class="d-flex p-3 flex-wrap justify-content-between align-items-sm-center">
      <div>
        <h5 class="mb-1">
          <span class="mr-1" v-text="registration.fullname" />
          <span class="text-muted d-inline-block" v-text="registration.birthday" />
        </h5>
        <strong class="d-block font-size-sm text-muted" v-text="registration.timetable.campType" />
        <span class="font-size-sm">
          <strong v-if="registration.isReserve" class="text-danger d-inline-block mr-1">Reservplats</strong>
          <strong v-if="registration.reservePaymentPending" class="d-block text-primary d-inline-block mr-1">
            Erbjuden plats (gäller till {{ validUntil }})
          </strong>
          <span v-if="registration.timetable" class="d-block d-lg-inline mb-2 mb-lg-0">{{
            registration.timetable.extendedDescription }}</span>
        </span>
      </div>
      <div class="mb-sm-2">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import * as moment from 'moment-timezone';

import { Registration } from '@/store/models/orderables/registration/Registration.model';

@Component
export default class RegistrationCard extends Vue {
  @Prop({ type: Object, required: true })
  registration!: Registration;

  /**
   * If registration is locked from changes
   */
  @Prop(Boolean)
  completed!: boolean;

  get validUntil() {
    const date = moment(this.registration.cartItem.validUntil);

    return `${date.format('D MMM')} kl. ${date.format('HH:mm')}`;
  }
}
</script>
