import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import * as countries from 'i18n-iso-countries';
import svLocale from 'i18n-iso-countries/langs/sv.json';

import { CustomSelectOption } from '../CustomSelect/CustomSelect';

// Use swedish locale
countries.registerLocale(svLocale);

@Component
export default class CountrySelect extends Vue {
  /**
   * Input model
   */
  @Prop()
  value!: string;

  /**
   * Given countries will be placed first
   */
  @Prop({ type: Array, default: () => ['SE', 'DK', 'FI', 'NO'] })
  priorityCountries!: string[];

  /**
   * List of countries
   */
  countries: CustomSelectOption[] = [];

  created() {
    // Create list of countries
    const countryObj = countries.getNames('sv');
    this.countries = Object.entries(countryObj)
      .map(([value, text]) => ({
        value,
        text: Array.isArray(text) ? text[0] : text,
      }))
      .sort((a, b) => {
        // Place priority countries first
        let sortRes = this.getPriorityIndex(a.value) - this.getPriorityIndex(b.value);

        // Fallback to country name order
        if (sortRes === 0)
          sortRes = a.text.localeCompare(b.text);

        return sortRes;
      });

    // Add separator after priority countries
    if (this.priorityCountries.length > 0)
      this.countries.splice(this.priorityCountries.length, 0, {
        text: '',
        value: null,
        disabled: true,
      });
  }

  getPriorityIndex(code: string) {
    let index = this.priorityCountries.indexOf(code);
    if (index < 0)
      index = this.priorityCountries.length;

    return index;
  };

  /**
   * Get all listeners except input
   */
  get listeners() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { input, ...other } = this.$listeners;
    return other;
  }
}
