import Vue from 'vue';
import Vuex from 'vuex';
import { Module } from 'vuex-module-decorators';

import { state, getters, mutations, actions, RootState } from './root';

Vue.use(Vuex);

export const store = new Vuex.Store<RootState>({
  state,
  getters,
  mutations,
  actions,
});

// Auto-import Vuex modules from ./modules folder
const reqModule = require.context('./modules', true, /\.ts$/);
reqModule.keys().forEach((file) => {
  reqModule(file);
});

/**
 * Wrap @Module decorator from vuex-module-decorators
 * to auto-fill some arguments
 */
export function DynamicModule(...args: any[]) {
  const defaultArgs = {
    dynamic: true,
    namespaced: true,
    store,
  };

  Object.assign(args[0] || {}, { ...defaultArgs, ...args });
  // @ts-ignore
  return Class => Module(...args)(Class);
}
