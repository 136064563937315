import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Generate class condition object based on variant prop, like:
 * {
 *   'my-component-primary': true,
 *   'my-component-danger': false,
 * }
 *
 * @param prefix Class prefix
 */
export function VariantMixin(prefix?: string) {
  const themeColors = [
    'primary',
    'danger',
    'info',
  ];

  @Component
  class VariantMixinClass extends Vue {
    @Prop(String)
    variant!: string;

    get variantClasses() {
      return themeColors.reduce((classes: object, color: string) => {
        // Add prefix if provided
        const colorClass = prefix ? `${prefix}-${color}` : color;

        // If variant is used in combination with the state
        // mixin, the state color shoud override variant.
        let forceUse = false;
        if (Object.prototype.hasOwnProperty.call(this, 'state'))
          forceUse = (
            // @ts-ignore
            (color === 'danger' && this.state === false) ||
            // @ts-ignore
            (color === 'success' && this.state === true)
          );

        classes[colorClass] = this.variant === color || forceUse;
        return classes;
      }, {});
    }
  }

  return VariantMixinClass as Record<string, any>;
}

/**
 * Add large/block props with classes
 * @param prefix Class prefix
 */
export const SizingMixin = (prefix?: string) => {
  const classes = ['lg', 'block'];

  @Component
  class SizingMixinClass extends Vue {
    @Prop(Boolean)
    lg!: boolean;

    @Prop(Boolean)
    block!: boolean;

    get sizingClasses() {
      return classes.reduce((classes: object, prop: string) => {
        // Add prefix if provided
        const colorClass = prefix ? `${prefix}-${prop}` : prop;

        classes[colorClass] = this[prop];
        return classes;
      }, {});
    }
  }

  return SizingMixinClass as Record<string, any>;
};

/**
 * Add state props with classes
 * @param prefix Class prefix
 */
@Component
export class StateMixin extends Vue {
  @Prop({ type: Boolean, default: null })
  state!: boolean | null;

  get stateClasses() {
    return {
      'is-valid': this.state === true,
      'is-invalid': this.state === false,
    };
  }
}
