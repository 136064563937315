import Vue from 'vue';
import { VuexModule, Mutation } from 'vuex-module-decorators';

import { DynamicModule } from '../index';
import { CartItem } from '../models/CartItem.model';
import { CartDiscount } from '../models/CartDiscount.model';
import { Registration } from '../models/orderables/registration/Registration.model';
import { CartOrderable } from '../models/CartOrderable.model';

interface ArticleSummaryGroup {
  articles: CartItem[];
  discounts: CartDiscount[];
}

interface ArticleSummary {
  groups: ArticleSummaryGroup[];
  discounts: CartDiscount[];
}
@DynamicModule({ name: 'Cart' })
export class Cart extends VuexModule {
  /**
   * Stadium loyalty civic number
   */
  loyaltyNumber = '';

  /**
   * User phone number
   */
  userPhone = '';

  /**
   * Cart items
   */
  articles: CartItem[] = [];

  /**
   * Cart discounts
   */
  discounts: CartDiscount[] = [];

  /**
   * Use thousand separator (whitespace)
   * @param price Price
   * @param discount If sum should always be negative
   */
  static formatPrice(price: number, discount = false) {
    const str = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return discount ? str.replace(/^-?/, '-') : str;
  }

  /**
   * Check if cart is empty
   */
  get isEmpty() {
    return this.articles.length === 0;
  }

  /**
   * Get total price with discounts
   */
  get sum() {
    const totalSum = this.articles
      .reduce((price, article) => price + article.priceWithDiscounts, 0);

    return this.discounts.reduce((price, discount) => price + discount.effect, totalSum);
  }

  /**
   * Get articles by orderable type
   */
  get articlesByOrderableType() {
    return <T>(type: typeof CartOrderable) => this.articles.filter(a => a.orderable instanceof type) as CartItem<T>[];
  }

  /**
   * Get summarized list of articles and discounts
   */
  get summary(): ArticleSummary {
    const { articles } = this;

    const groups: ArticleSummaryGroup[] = [];
    let group!: ArticleSummaryGroup;
    let lastArticle = {
      title: '',
      price: 0,
    };

    for (let i = 0; i < articles.length; i++) {
      // Create new subgroup if new article type
      if (articles[i].title !== lastArticle.title || articles[i].price !== lastArticle.price) {
        group = {
          articles: [],
          discounts: [],
        };
        groups.push(group);
      }

      // Add article to subgroup
      group.articles.push(articles[i]);
      // Add discounts from article
      group.discounts.push(...articles[i].discounts);

      lastArticle = {
        title: articles[i].title,
        price: articles[i].price,
      };
    }

    // Return list of article groups
    return {
      groups,
      discounts: this.discounts,
    };
  }

  /**
   * Load cart from GraphQL
   */
  @Mutation
  loadFromGQL({ cart, user }: { cart: GQL.CartQuery['cart']; user: GQL.CartQuery['user'] }) {
    this.loyaltyNumber = user!.profile!.civicNumber!;
    this.userPhone = user!.profile!.phone!;

    this.articles = cart.cartItems!.map((cartItem) => {
      const article = new CartItem(cartItem);
      article.setDiscounts(cartItem.discounts.map(d => new CartDiscount(d.title, d.priceEffect)));

      if (cartItem.orderableType === 'Registration')
        article.setOrderable(Registration.loadFromGQL(cartItem.orderable as GQL.RegistrationInfoFragment));
      else
        article.setOrderable(CartOrderable.createGeneralOrderable(cartItem.orderable));

      return article;
    });
  }

  /**
   * Update stadium loyalty number
   * @param number Civic number
   */
  @Mutation
  setLoyaltyNumber(number: string) {
    this.loyaltyNumber = number;
  }

  /**
   * Update user phone number
   * @param number Phone number
   */
  @Mutation
  setUserPhone(number: string) {
    this.userPhone = number;
  }

  /**
   * Add or update cart item
   * @param article Cart item
   */
  @Mutation
  addOrUpdateArticle(article: CartItem) {
    // Check if article already exists
    const articleIndex = this.articles
      .findIndex(p => p.id === article.id);

    // Update article if exists
    if (articleIndex >= 0)
      Vue.set(this.articles, articleIndex, article);

    // Add article if not exists
    else this.articles.push(article);
  }

  /**
   * Delete article from cart
   * @param article Cart item
   */
  @Mutation
  deleteArticle(article: CartItem) {
    const index = this.articles.indexOf(article);

    if (index >= 0)
      this.articles.splice(index, 1);
  }
}
