import Vue from 'vue';

// Lazy load routes
const EntrollmentIndexPage = () => import('@/pages/enrollment/checkout/index/index.vue');
const EnrollmentAddRegistrationPage = () => import('@/pages/enrollment/checkout/add-registration/add-registration.vue');
const EntrollmentSupplementIndexPage = () => import('@/pages/enrollment/supplement/index/index.vue');
const EntrollmentSupplementRegistrationPage = () => import('@/pages/enrollment/supplement/registration/registration.vue');

export enum Routes {
  CheckoutIndex = 'CheckoutIndex',
  CheckoutAddRegistration = 'CheckoutAddRegistration',
  SupplementIndex = 'SupplementIndex',
  SupplementRegistration = 'SupplementRegistration',
}

declare module 'vue/types/vue' {
  interface Vue {
    Routes: typeof Routes;
  }
}

// Inject enum into components
Vue.prototype.Routes = Routes;

export const routes = [
  {
    name: Routes.CheckoutIndex,
    path: '/anmalan/varukorg',
    component: EntrollmentIndexPage,
  },
  {
    name: Routes.CheckoutAddRegistration,
    path: '/anmalan/varukorg/deltagare',
    component: EnrollmentAddRegistrationPage,
  },
  {
    name: Routes.SupplementIndex,
    path: '/anmalan/oversikt',
    component: EntrollmentSupplementIndexPage,
  },
  {
    name: Routes.SupplementRegistration,
    path: '/anmalan/komplettering/:registrationId',
    component: EntrollmentSupplementRegistrationPage,
  },
];
