import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  initialize() {
    this.element.addEventListener('turbo:submit-start', ({ detail: { formSubmission } }) => {
      formSubmission.fetchRequest.headers.Accept = 'text/vnd.turbo-stream.html';

      if (formSubmission.formElement.dataset.turboAction === 'advance')
        window.history.pushState(null, null, formSubmission.fetchRequest.url.href);
    });
  }
}
