import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  get input() {
    return this.element.querySelector('input');
  }

  get a() {
    return this.element.querySelector('a[href]');
  }

  connect() {
    this.input.addEventListener('change', this.onChange.bind(this));
  }

  disconnect() {
    this.input.removeEventListener('change', this.onChange.bind(this));
  }

  onChange() {
    let action = this.a.href;
    const searchQuery = new URL(location.href).searchParams.get('query');

    if (searchQuery) {
      const url = new URL(action);

      if (searchQuery)
        url.searchParams.set('query', searchQuery);
      else
        url.searchParams.delete('query');

      action = url.toString();
    }

    this.a.href = action;
    this.a.click();
  }
}
