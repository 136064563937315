/**
 * Vuex root state
 */

export type RootState = Record<string, any>;

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {};
