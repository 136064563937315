import { VuexModule, Mutation } from 'vuex-module-decorators';

import { DynamicModule } from '../index';

@DynamicModule({ name: 'Site' })
export class Site extends VuexModule {
  cities: GQL.City[] = [];

  get cityById() {
    return (id: string) => {
      return this.cities.find(c => c.id === id);
    };
  }

  @Mutation
  setCities(cities) {
    this.cities = cities;
  }
}
