import { Vue, Component, Inject } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import registrationsQuery from '@/graphql/queries/registrations.graphql';
import { Registration } from '@/store/models/orderables/registration/Registration.model';
import { Cart } from '@/store/modules/Cart';
import { CartItem } from '@/store/models/CartItem.model';

@Component({
  apollo: {
    user: {
      query: registrationsQuery,
      result({ data }: { data: GQL.RegistrationsQuery }) {
        this.previousRegistrations = data.user!.registrations!;
      },
    },
  },
})
export default class RegistrationInfoStep extends Vue {
  /**
   * Inject from parent page component
   */
  @Inject()
  cartItem!: CartItem<Registration>;

  previousRegistrations: GQL.Registration[] = [];
  error = false;
  invalidPromoCode = false;

  genderOptions = [
    { text: 'Kille', value: 'male' },
    { text: 'Tjej', value: 'female' },
  ];

  created() {
    this.$on('invalid-promocode', () => {
      this.invalidPromoCode = true;
    });
  }

  mounted() {
    if (!this.registration.city) {
      this.registration.city = this.$route.params.city;
      this.$forceUpdate();
    }
  }

  get registration() {
    return this.cartItem.orderable;
  }

  get cart() {
    return getModule(Cart);
  }

  /**
   * Get other registrations in cart + other user registrations
   */
  get otherRegistrations() {
    const { cart, registration } = this;

    const otherCartRegistrations = cart
      .articlesByOrderableType<GQL.Registration>(Registration)
      .filter(a => !registration.cartItem || a.id !== registration.cartItem.id)
      .map(a => a.orderable as Registration);

    const otherUserRegistrations = this.previousRegistrations;
    return [
      ...otherUserRegistrations,
      ...otherCartRegistrations,
    ];
  }

  get siblings() {
    return this.otherRegistrations
      // Group registrations of same person
      .map(p => ({ ...p, $id: [p.firstName, p.lastName, p.birthday].join('/') }))
      .filter((name, index, all) => all.findIndex(p => p.$id === name.$id) === index);
  }

  /**
   * Convert array to sentence
   */
  arrayToSentence(arr: string[]) {
    if (arr.length === 1)
      return arr[0];

    const last = arr.pop();
    return arr.join(', ') + ' eller ' + last;
  }

  /**
   * Validate step
   */
  validate() {
    const form = this.$refs.form as HTMLFormElement;
    return form.checkValidity();
  }

  onValidationError() {
    this.error = true;
  }

  onLeave() {
    this.error = false;
  }

  formatBirthday() {
    this.registration.formattedBirthday = this.registration.formattedBirthday.replace(/^([0-9]{4}).*([0-9]{2}).*([0-9]{2})$/, '$1 - $2 - $3');
  }
}
