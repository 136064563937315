<template>
  <form-wizard-step title="Val av sport" :validate="validate" :before-leave="onBeforeLeave" @error="onValidationError"
    @leave="onLeave">
    <form ref="form" :class="{ 'was-validated': error }">
      <div class="mb-4">
        <strong class="font-size-sm">Vald sport</strong><br>
        <span>{{ registration.timetable }}</span>
      </div>
      <custom-select v-if="positionsExistsOnSport" id="positionPreferences" v-model="registration.details.sportPosition"
        label="Önskemål om position/inriktning" :grouped-options="sportPositions" required />
      <div class="alert alert-info font-size-sm">
        <strong>Varför behöver ni de här uppgifterna?</strong>
        <p class="mb-0">
          Genom att ange önskad position/inriktning hjälper du oss att planera grupperna så att ditt barn får en så bra
          upplevelse som möjligt på campet. Fråga ditt barn om du känner dig osäker.
        </p>
      </div>
      <custom-select id="shirtSize" v-model="registration.details.tshirtId" label="T-shirtstorlek"
        :options="tshirtSizes" required />
      <custom-input id="sportsClub" v-model="registration.details.club" label="Klubb/förening"
        help-label="Utövar deltagaren den valda sporten i någon klubb eller förening?" optional autocomplete="off" />
      <form-wizard-next-button />
    </form>
  </form-wizard-step>
</template>

<script lang="ts">
import { Vue, Component, Inject } from 'vue-property-decorator';
import { CustomSelectOption, CustomSelectOptionGroup } from '@/components/inputs/CustomSelect/CustomSelect';
import { Registration, RegistrationDetailsAttribute } from '@/store/models/orderables/registration/Registration.model';
import sportDetailsQuery from '@/graphql/queries/sportDetails.graphql';

@Component
export default class SportDetailsStep extends Vue {
  /**
   * Inject from parent page component
   */
  @Inject()
  registration!: Registration;

  error = false;

  sportPositions: CustomSelectOptionGroup[] = [];
  tshirtSizes: CustomSelectOption[] = [];

  created() {
    this.$apollo.query<GQL.SportDetailsQuery>(
      {
        query: sportDetailsQuery,
        variables: {
          id: this.registration.timetable.timetable.sport.id,
          cityId: this.registration.timetable.city.id,
        },
      },
    ).then(({ data }) => {
      // Set positions options
      this.sportPositions = data.sport!.positionCategories!.map(p => ({
        text: p.name,
        options: p.items.map(i => i.name),
      }));

      // Set tshirt options
      this.tshirtSizes = data.sport!.tshirts!
        .sort((a, b) => a.position - b.position)
        .map(t => ({
          text: t.title,
          value: t.id,
        }));
    });
  }

  get positionsExistsOnSport() {
    return this.sportPositions.length > 0;
  }

  validate() {
    const form = this.$refs.form as HTMLFormElement;
    return form.checkValidity();
  }

  onValidationError() {
    this.error = true;
  }

  onBeforeLeave() {
    const attributes: RegistrationDetailsAttribute[] = [
      'tshirtId',
      'club',
    ];

    if (this.positionsExistsOnSport)
      attributes.push('sportPosition');

    return this.registration.saveDetails(attributes);
  }

  onLeave() {
    this.error = false;
  }
}
</script>
