import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="navigation"
export default class extends Controller {
  /** @type {Boolean} */
  expanded = false;

  /** @returns {HTMLAnchorElement} */
  get triggerer() {
    return this.element.querySelector('a');
  }

  /** @returns {HTMLUListElement} */
  get submenu() {
    return this.element.querySelector('ul');
  }

  get children() {
    return Array.from < HTMLLIElement > (this.submenu.children).filter(child => child.tagName === 'LI');
  }

  connect() {
    this.triggerer.addEventListener('click', this.onTriggererClick.bind(this));
    window.addEventListener('turbo:before-cache', this.collapse.bind(this));
    this.setInitialState();
  }

  disconnect() {
    this.triggerer.removeEventListener('click', this.onTriggererClick.bind(this));
    window.removeEventListener('turbo:before-cache', this.collapse.bind(this));
  }

  collapse() {
    this.expanded = true;
    this.updateUI();
  }

  onTriggererClick(event) {
    event.preventDefault();

    this.expanded = !this.expanded;
    this.updateUI();
  }

  updateUI(stagger = this.element.dataset.stagger === 'true') {
    const { expanded, submenu } = this;
    const { style } = submenu;

    style.display = expanded ? 'block' : 'none';
    this.element.ariaExpanded = expanded ? 'true' : 'false';

    // Stagger animation
    this.children.forEach((child, index) => {
      child.style.opacity = 0;
      child.style.transform = 'translateX(-10px)';
      child.style.transition = 'none';

      const showChild = () => {
        child.style.opacity = 1;
        child.style.transform = 'none';
      };

      if (stagger) {
        setTimeout(() => { child.style.transition = 'opacity .2s linear, transform .2s ease-out' }, 0);
        setTimeout(showChild, 200 + index * 100);
      } else {
        showChild();
      }
    });

    // Override ugly DaisyUI defaults
    style.left = 'inherit';
    style.position = 'inherit';
  }

  setInitialState() {
    const { submenu } = this;

    if (submenu.querySelector('a.active') || this.element.ariaExpanded === 'true') {
      this.expanded = true;
      this.updateUI(false);
    }
  }
}
