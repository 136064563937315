import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('change', this.onChange.bind(this));

    if (sessionStorage.getItem(this.element.id) === 'false')
      this.element.click();
  }

  disconnect() {
    this.element.removeEventListener('change', this.onChange.bind(this));
  }

  onChange(/** @type {InputEvent} */ event) {
    const { checked } = event.target;
    sessionStorage.setItem(this.element.id, checked);
  }
}
