import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import cartQuery from '@/graphql/queries/cart.graphql';
import { apolloClient } from '@/graphql/apollo';
import { Cart } from '@/store/modules/Cart';

export const fetchCart = () => {
  return apolloClient.query<GQL.CartQuery>({
    query: cartQuery,
  }).then(({ data }) => {
    getModule(Cart).loadFromGQL({
      cart: data.cart,
      user: data.user!,
    });
  });
};

@Component
export class LoadGQLCartMixin extends Vue {
  cartIsLoading = true;
  loadError = false;

  created() {
    fetchCart()
      .then(() => { this.cartIsLoading = false })
      .then(() => this.$emit('cart-loaded'))
      .catch(({ graphQLErrors }) => {
        const [err] = graphQLErrors;
        if (err.extensions.code === 'PENDING_PAYMENT')
          return location.reload();

        this.loadError = true;
        this.cartIsLoading = false;
      });
  }

  fetchCart() {
    return fetchCart;
  }
}
