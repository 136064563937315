export class CartDiscount {
  /**
   * Discount title in cart
   */
  title!: string;

  /**
   * Calculated effect on price
   */
  effect = 0;

  constructor(title: string, effect: number) {
    this.title = title;
    this.effect = effect;
  }
}
