import ApolloClient, { QueryOptions, OperationVariables } from 'apollo-boost';
import VueApollo from 'vue-apollo';

const defaultQueryOptions: Partial<QueryOptions<OperationVariables>> = {
  fetchPolicy: 'no-cache',
};

export const apolloClient = new ApolloClient({
  uri: '/graphql',
});
apolloClient.defaultOptions.query = defaultQueryOptions;

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $query: defaultQueryOptions,
  },
});
