import { Component, Prop, Mixins } from 'vue-property-decorator';

import { LoadUserRegistrationsMixin } from '@/mixins/LoadUserRegistrationsMixin';

@Component
export default class ChooseRegistrationStep extends Mixins(LoadUserRegistrationsMixin) {
  @Prop()
  value;

  error = false;

  get registrationsOptions() {
    return this.userRegistrations
      .filter(registration => !registration.isReserve)
      .map(registration => ({
        text: `${registration.firstName} ${registration.lastName}`,
        value: `${registration.id}`,
        timetable: registration.timetable,
        registration,
      }));
  }

  get completedTitle() {
    const registration = this.userRegistrations.find(r => r.id === this.value);

    if (registration)
      return () => `Deltagare: ${registration.firstName} ${registration.lastName}`;
  }
}
