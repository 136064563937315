interface RegistrationAddress {
  street?: string | null;
  zipcode?: string | null;
  city?: string | null;
  country?: string | null;
}

export class RegistrationDetails implements Omit<GQL.UpdateRegistrationInput, 'id'> {
  /**
   * Contact details
   */
  address: RegistrationAddress = {
    street: '',
    zipcode: '',
    city: '',
    country: 'SE',
  };

  phone = '';
  previousEnrollment = false;

  /**
   * Sport details
   */
  sportPosition: string = '';
  tshirtId: string | null = null;
  club = '';

  /**
   * Contact person and friends
   */
  relativeName = '';
  relativeRelation = '';
  relativePhone = '';

  relative2Name = '';
  relative2Relation = '';
  relative2Phone = '';

  friends = '';
  allowPhoto: boolean | null = null;

  /**
   * Health details
   */
  foodAllergyListRaw: GQL.FoodAllergy[] = [];
  get foodAllergyList() {
    return this.foodAllergyListRaw
      .filter(allergy => allergy.value)
      .map(allergy => allergy.name);
  }

  foodAllergyDescription = '';
  disorderDescription = '';
  medicationDescription = '';
  swimAllowed: boolean | null = null;

  /**
   * Other info
   */
  otherInfo = '';

  _hasFoodAllergy: boolean | null = null;
  _hasDisorder: boolean | null = null;
  _hasMedication: boolean | null = null;

  get hasFoodAllergy() { return this._hasFoodAllergy !== null ? this.foodAllergyList.length > 0 || this.foodAllergyDescription.length > 0 : null }
  get hasDisorder() { return this._hasDisorder !== null ? this.disorderDescription.length > 0 : null }
  get hasMedication() { return this._hasMedication !== null ? this.medicationDescription.length > 0 : null }
}

/**
 * Create options for radio group
 * @param yes Yes label
 * @param no No label
 */
export const createYesNoOptions = (yes = 'Ja', no = 'Nej') => {
  return [
    {
      text: yes,
      value: true,
    },
    {
      text: no,
      value: false,
    },
  ];
};
