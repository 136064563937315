import { Vue, Component, Prop } from 'vue-property-decorator';

import { VariantMixin, StateMixin } from '@/mixins/ClassHelperMixins';

@Component({
  inheritAttrs: false,
  mixins: [
    VariantMixin('custom-box-input'),
    StateMixin,
  ],
})
export default class CustomInput extends Vue {
  /**
   * Input model
   */
  @Prop(String)
  value!: string;

  /**
   * Custom input type
   */
  @Prop({ type: String, default: 'text' })
  type!: string;

  /**
   * Optional formatter function
   */
  @Prop({ type: Function, default: v => v })
  formatter!: (v: string) => string;

  /**
   * Input label
   */
  @Prop(String)
  label!: string;

  /**
   * Bottom help text
   */
  @Prop(String)
  helpLabel!: string;

  /**
   * Message to display on validation error
   */
  @Prop(String)
  invalidFeedback!: string;

  /**
   * Whether input is optional
   */
  @Prop(Boolean)
  optional!: boolean;

  /**
   * Additional classes to add on input
   */
  @Prop()
  inputClass!: any;

  /**
   * Filter input characters
   */
  @Prop(RegExp)
  allowedCharacters?: RegExp;

  mounted() {
    // Autofocus if requested
    if (this.$attrs.autofocus !== undefined)
      (this.$refs.input as HTMLInputElement).focus();
  }

  /**
   * Unique component id
   */
  get cid() {
    // @ts-ignore
    return this.$attrs.id || `custom-input-${this._uid}`;
  }

  get input() {
    return this.$refs.input as HTMLInputElement;
  }

  /**
   * Get all listeners except input
   */
  get listeners() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { input, ...other } = this.$listeners;
    return other;
  }

  get inputClassObject() {
    const c = this.inputClass;

    // If string
    if (typeof c === 'string')
      return { [c]: true };

    // If array
    if (Array.isArray(c))
      return c.reduce((list, className) => { list[className] = true }, {});

    // If object
    return c;
  }

  /**
   * Validate characters from keypress or clipboard paste
   * @param event Keyboard or cliboard event
   */
  checkValidCharacters(event: KeyboardEvent & ClipboardEvent) {
    if (!this.allowedCharacters)
      return true;

    let character;
    if (event.type === 'paste' && event.clipboardData)
      character = event.clipboardData.getData('text/plain');
    else
      character = event.key || String.fromCharCode(event.keyCode || event.which);

    if (this.allowedCharacters.test(character))
      return true;

    event.preventDefault();
    return false;
  }
}
