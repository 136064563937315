import { Vue, Component, Prop, Model } from 'vue-property-decorator';

import { VariantMixin, SizingMixin, StateMixin } from '@/mixins/ClassHelperMixins';

@Component({
  inheritAttrs: false,
  mixins: [
    VariantMixin('custom-box-checkbox'),
    SizingMixin('custom-box-checkbox'),
    StateMixin,
  ],
})
export default class CheckboxInput extends Vue {
  /**
   * Checkbox model
   */
  @Model('change')
  @Prop()
  checked!: boolean;

  /**
   * Checkbox checked value
   */
  @Prop({ default: true })
  value!: any;

  /**
   * Input label
   */
  @Prop(String)
  label!: string;

  /**
   * Input label
   */
  @Prop({ type: Boolean, default: false })
  important!: boolean;

  /**
   * Bottom help text
   */
  @Prop(String)
  helpLabel!: string;

  /**
   * Message to display on validation error
   */
  @Prop(String)
  invalidFeedback!: string;

  /**
   * Unique component id
   */
  get cid() {
    // @ts-ignore
    return this.$attrs.name || `checkbox-input-${this._uid}`;
  }

  /**
   * Get all listeners except change
   */
  get listeners() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { change, ...other } = this.$listeners;
    return other;
  }
}
