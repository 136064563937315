import { Controller } from '@hotwired/stimulus';

export default class EnrollmentTimelineController extends Controller {
  static targets = ['meter', 'value'];

  intervalTimer = null;

  /** @type {HTMLInputElement} */
  get input() {
    return this.valueTarget;
  }

  connect() {
    this.fetch();
    this.resetInterval();
    this.input.addEventListener('change', () => {
      this.resetInterval();
      this.fetch();
    });
  }

  disconnect() {
    this.clearTimers();
  }

  clearTimers() {
    if (this.intervalTimer) clearInterval(this.intervalTimer);
    this.intervalTimer = null;
  }

  resetInterval() {
    this.clearTimers();
    this.intervalTimer = setInterval(() => this.fetch(), 10000);
  }

  update(/** @type {HTMLDivElement} */ el, value) {
    if (el.getAttribute('aria-valuenow') == value) return;

    el.parentElement.parentElement.parentElement.classList.add('flash');
    setTimeout(() => el.parentElement.parentElement.parentElement.classList.remove('flash'), 3000);

    el.setAttribute('aria-valuenow', value);
    el.style.width = value + '%';
  }

  async fetch() {
    /** @type {{ label: string, data: Record<string, number> }} */
    const payload = await fetch(`/admin/timeline/data?value=${this.input.value}`).then(response => response.json());
    const { data, ...elements } = payload;

    Object.entries(elements).forEach(([key, value]) => { this.element.querySelector(`#${key}`).innerHTML = value });
    this.meterTargets.forEach(meter => this.update(meter, data[meter.id]));
  }
}
